import request from '@/utils/request';

const base = '/Filemanagenew/'

// 创建文件夹 pid父级id title文件夹名称
export const createFolderApi = (data: object) => {
	return request({
		url: `${base}addfolder`,
		method: 'post',
		data,
	});
}

// 文件夹重命名  id文件夹id title文件夹名称
export const renameFolderApi = (data: object) => {
	return request({
		url: `${base}editfolder`,
		method: 'post',
		data,
	});
}

// 获取文件列表  limit每页条数 title 关键词查询  wjj_pid文件夹id
export const getFileListApi = (data: object) => {
	return request({
		url: `${base}lst`,
		method: 'post',
		data,
	});
}

// 删除文件/文件夹  id 文件/文件夹id，多个id用逗号隔开
export const deleteFileApi = (data: object) => {
	return request({
		url: `${base}delfolder`,
		method: 'post',
		data,
	});
}

// 设置小程序封面  id文件id url小程序封面
export const setCoverApi = (data: object) => {
	return request({
		url: `${base}xcximg`,
		method: 'post',
		data,
	});
}

// 文件/文件夹 转移
export const moveFileApi = (data: object) => {
	return request({
		url: `${base}zy`,
		method: 'post',
		data,
	});
}

// 文件备注  id文件id intro 备注
export const setFileIntroApi = (data: object) => {
	return request({
		url: `${base}editintro`,
		method: 'post',
		data,
	});
}

// 创建子链接  和加密参数一样 title子标题  id父id
export const createSubLinkApi = (data: object) => {
	return request({
		url: `${base}childurl`,
		method: 'post',
		data,
	});
}

// 子链接列表 id父id  limit page
export const getSubLinkListApi = (data: object) => {
	return request({
		url: `${base}childlst`,
		method: 'post',
		data,
	});
}

// 文件夹列表 pid父级id 文件夹id，传值查子级
export const getFolderListApi = (data: object) => {
	return request({
		url: `${base}wjjlst`,
		method: 'post',
		data,
	});
}

// 文件加密
/* id（文件id）
	looknum（打开次数(次)  默认未设置 0）
	password（访问密码  默认为设置 空）
	one_look_time（单词访问时长(秒)  默认未设置  0）
	rennum（访问人数限制(人)  默认未设置  0）
	sctime（有效期  默认永久:0、1天:1、7天:2、1个月:3 、指定日期：2023-12-18）
  dan_look_num单人访问次数
  read_yz 阅读验证 0无 1微信验证 2申请授权 3设置白名单
  bmd 白名单 多个用,隔开
  yxzj 营销组件 0无 1展示表单 2展示二维码
  password_num 预览到第几页需要密码
  sy 水印
  dayin 限制选项打印 0关闭 1打开
  xiazai 限制选项下载 0关闭 1打开
  pc_jietu  限制选项pc截图 0关闭 1打开
  phone_jietu 限制选项手机截图 0关闭 1打开
*/
export const setFileEncryptApi = (data: object) => {
	return request({
		url: `${base}encrypt`,
		method: 'post',
		data,
	});
}

// 文件访客数据汇总
export const getFileVisitorDataApi = (data: object) => {
	return request({
		url: `/ip/zongfang`,
		method: 'post',
		data,
	});
}

// 文件访客数据下载
export const getFileVisitorDataDownApi = (id: number) => {
	window.open(`/api/ip/fangkedc?id=${id}`, '_blank')
}

// 文件轨迹数据下载
export const getFileTrackDataDownApi = (id: number) => {
	window.open(`/api/ip/infodc?id=${id}`, '_blank')
}

// 获取文件小程序地址
export const getFileMiniProgramUrlApi = (id: number) => {
	return request({
		url: `${base}appletQrcode`,
		method: 'get',
		params: { id },
	});
}
