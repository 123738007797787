
import { ref, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useUserStore } from '@/store/modules/user'
import { isWxBrowser, isWxWorkBrowser } from '@/utils/common'
import { ElMessage } from 'element-plus'
import ClipboardJS from 'clipboard';
import { useUser } from "@/composable/useUser"
import { getFileMiniProgramUrlApi } from '@/api/file/new'

export const useFile = () => {
  
  const { handleVipTip } = useUser()

  const userStore = useUserStore()
  const { wechatShareLink, qrCodeSize: codeSize } = storeToRefs(userStore)

  const isWx = computed(() => isWxBrowser())
  const isWxWork = computed(() => isWxWorkBrowser())

  // 保存二维码提示文字
  const saveQrCodeTips = computed(() => isWx.value ? '长按二维码保存' : '保存二维码')

  // 发送文件 文字
  const shareText = computed(() => isWx.value? '发送微信文件' : '发送微信文件教程')


  // 生成二维码的尺寸
  const qrCodeSize = computed(() => codeSize.value)

  // 保存二维码
  const saveQrCode = (qrCodeImg: any, data: any) => {
  // 微信里打开 提示长按二维码保存图片
    if (isWx.value && data.save_type !== 'download') return ElMessage({ message: '长按二维码保存' })
    const a = document.createElement('a');
    a.href = qrCodeImg;
    a.download = `${data.title}.png`;
    a.click();
  }

  // 微信分享链接
  const shareLink = (data: any = {}) => {
    if (isWx.value && data.share_url) {
      window.open(data.share_url, '_blank')
      return
    }
    if (wechatShareLink.value) {
      window.open(wechatShareLink.value, '_blank')
    } else {
      userStore.getWechatShareLink()
        .then(() => {
          window.open(wechatShareLink.value, '_blank')
        })
    }
  }

  // 小程序分享
  const getShareAppletUrl = (id: number) => {
    return new Promise((resolve, reject) => { 
      getFileMiniProgramUrlApi(id).then((res: any) => {
        resolve(res)
      }).catch((error: any) => {
        reject(error)
      })
    })
  }

  // 打开小程序
  const shareApplet = (url: string) => {
    if (url) {
      window.open(url, '_blank')
    } else {
      ElMessage.error('小程序地址获取中，请稍后再试')
    }
  }
  
  /**
   * 复制链接
   */
  const copyLink = async (type = '') => {
    if (type === 'all') {
      try {
        const status = await handleVipTip('batch_sharing', true);
        if (!status) return false
        else {
          new ClipboardJS('.copy-btn-all');
          ElMessage.success('复制成功');
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      new ClipboardJS('.copy-btn');
      ElMessage.success('复制成功');
    }
  };

  // 加密有效期选项
  const validityOptions = ref([
    { label: '永久', value: 0 },
    { label: '1天', value: 1 },
    { label: '7天', value: 2 },
    { label: '30天', value: 3 },
    { label: '指定日期', value: 4 },
  ])

  // 加密设置 限制选项
  const encryptionOptions = ref([
    { label: '打印', value: 'dayin' },
    { label: '下载', value: 'xiazai' },
    { label: '电脑截图', value: 'pc_jietu' },
    { label: '手机截图', value: 'phone_jietu' }
  ])

  // 阅读验证
  const readVerification = ref([
    { label: '无', value: 0 },
    { label: '微信验证', value: 1 },
    { label: '申请授权', value: 2 },
    // { label: '选择白名单', value: 3 }
  ])

  // 营销组件
  const marketingOptions = ref([
    { label: '展示表单', value: 1 },
    { label: '展示二维码', value: 2 }
  ])

  return { 
    saveQrCode,
    shareLink,
    saveQrCodeTips,
    isWx,
    isWxWork,
    qrCodeSize,
    validityOptions,
    encryptionOptions,
    readVerification,
    marketingOptions,
    copyLink,
    shareText,
    getShareAppletUrl,
    shareApplet
  }
}