import axios, { AxiosInstance } from 'axios';
import { ElMessage } from 'element-plus';
import { getToken, removeToken, getUserId, removeUserId } from "@/utils/auth";
import { clearCookie } from "@/utils/common";
import qs from 'qs';
import router from '@/router';

const { VUE_APP_PROXY_DOMAIN, NODE_ENV } = process.env;
const baseUrl = VUE_APP_PROXY_DOMAIN

// 配置新建一个 axios 实例
const service: AxiosInstance = axios.create({
	baseURL: baseUrl,
	// timeout: 1000 * 2 * 60 * 60,
	timeout: 0,  //永不超时
	headers: { 'Content-Type': 'application/json' },
	paramsSerializer: {
		serialize(params) {
			return qs.stringify(params, { allowDots: true });
		},
	},
});

// 添加请求拦截器
service.interceptors.request.use(
	(config: any) => {
		// 在发送请求之前做些什么 token
		let token = getToken();
		let userId = getUserId();
		if (token && NODE_ENV == 'development') {
			config.headers!['token'] = token;
		}
		if (userId) config.headers!['user'] = userId
		// console.log(config, 'config');
		if (config.url.includes("upload")) {
			config.headers['Content-Type'] = "multipart/form-data";
		}
		return config;
	},
	(error) => {
		// 对请求错误做些什么
		return Promise.reject(error);
	}
);

// 添加响应拦截器
service.interceptors.response.use(
	(response) => {

		const route = router.currentRoute.value
		// console.log(route, 'route');
		
		// 对响应数据做点什么
		const res = response.data;
		const config = response.config;
		const postUrl = config && config.url || '';
		if (postUrl.includes('equity/info')) return Promise.resolve(res)
		switch (res.code) {
			case 1:
				let data = res.data
				if (res.url) data = { url: res.url, data }
				else if (postUrl.includes('wxfx/index')) return Promise.resolve(res)
				return Promise.resolve(data);
			case 401:
				// Session.clear(); // 清除浏览器全部临时缓存
				clearCookie() // 清除cookie
				removeToken()
				removeUserId()
				window.location.href = '/'; // 去登录页
				ElMessage.error('您未登录或登录已过期，请重新登录');
				return Promise.reject(res);
				break
			case 0:
			case 2:
				if (hiddenError(response.config)) ElMessage.error(res.msg);
				return Promise.reject(res);
			default:
				return res
		}
	},
	(error) => {
		console.log(error, 'error');
		try {
			if (error.response.data.code == 401) {
				clearCookie() // 清除cookie
				removeToken()
				removeUserId()
				let href = window.location.href;
				// ElMessage.error('您未登录或登录已过期，请重新登录');
				if (error.response.config.url == '/user/userinfo') {
					if (!href.includes('/home') && !href.includes('/login') && !href.includes('/pdf/index')) {
						window.location.href = window.location.origin + '/#/login'; // 去登录页
					}
				} else {
					window.location.href = window.location.origin + '/#/login'; // 去登录页
				}
			}else {
				console.log(error)
				// 对响应错误做点什么
				ElMessage.error(error.msg || error.message || '请求错误');
				return Promise.reject(error);
			}
		}catch (e) {
			console.log(e);
		}
	}
);

const hiddenError = (config: any) => {
	switch (config.url) {
		case '/user/lunxun':
		case '/upload/checknum':
		case '/upload/checksize':
		case '/user/lxwx':
			return false
		default:
			return true
	}
}

// 导出 axios 实例
export default service;
